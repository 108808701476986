let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-pas-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-pas-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://hug5vhqkonesdpkvnov3v2vu6m.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://qt3nd6js3f.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://kgk4cf7b87.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.pas.forwoodsafety.com",
        WEBSOCKET: "wss://ko2233x0k2.execute-api.us-west-2.amazonaws.com/staging"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_mC3QIykTy",
        IDENTITY_POOL_ID: "us-west-2:f089d14d-d477-402a-aac5-e716d8eed576",
        USERPOOL_HOSTED_DOMAIN: "forwood-pas-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.pas.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.pas.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::247653255038:role/staging-CA"
    },
    samlProvider: {
      NAME: 'pas',
      SUPPORTED_LIST: 'ForwoodAzure,pas'
    },
    reactApp: {
        VERSION: "1.35.13",
        HOSTNAME: "id.staging.pas.forwoodsafety.com",
        ENV_NAME: "staging",
        COOKIE_DOMAIN: ".staging.pas.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.pas.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
